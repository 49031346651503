const middleware = {}

middleware['ralph-authenticated'] = require('../middleware/ralph-authenticated.js')
middleware['ralph-authenticated'] = middleware['ralph-authenticated'].default || middleware['ralph-authenticated']

middleware['ralph-default'] = require('../middleware/ralph-default.js')
middleware['ralph-default'] = middleware['ralph-default'].default || middleware['ralph-default']

middleware['ralph-list-page-routing'] = require('../middleware/ralph-list-page-routing.js')
middleware['ralph-list-page-routing'] = middleware['ralph-list-page-routing'].default || middleware['ralph-list-page-routing']

export default middleware
