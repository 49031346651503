export default {
  CUSTOMER_TYPE_PERSON: 'Privatperson',
  CUSTOMER_TYPE_ORGANIZATION: 'Företag',
  USP_1: 'Fri frakt över 299:-',
  USP_2: 'Snabb leverans',
  USP_3: 'Fria returer',
  USP_TEXT: 'Alltid fri frakt över 299:-',
  CUSTOMER_SERVICE: 'Kundtjänst',
  CONTACT_TITLE: 'Kontakta oss',
  GENERAL_SHIPPING_INFORMATION: 'Vi erbjuder flera smidiga leveransalternativ beroende på ditt postnummer, såsom Budbee Box, Early Bird, Instabox och DB Schenker. Vid köp över 299 kr är leveransen kostnadsfri, annars tillkommer en fraktavgift från 29 kr. Välj det alternativ som passar dig bäst för en bekväm leverans.',
  GENERAL_PAYMENT_INFORMATION: 'Du kan betala tryggt och enkelt via Avarda med flera alternativ: Swish för snabb betalning, kortbetalning med VISA eller MasterCard, faktura med 30 dagars betalningstid, eller konto för flexibel delbetalning.',
  FAVORITES_LABEL: 'Mina favoriter',
  FAVORITES_EMPTY: 'Du har inga favoriter sparade',
  BRANDS_TITLE: 'Alla författare',
  BRANDS_EMPTY: 'Inga författare finns tillgängliga',
  SEARCH: 'Sök',
  SEARCH_PLACEHOLDER: 'Sök bland tusentals titlar',
  SEARCH_SEE_ALL: 'Se alla sökresultat',
  SEARCH_SEE_ALL_BRANDS: 'Se alla författare',
  PRODUCT_LOWERCASE: 'produkt | produkter',
  PRODUCT: 'Produkt | Produkter',
  BRANDS: 'Författare',
  CATEGORIES: 'Kategorier',
  PRODUCT_LIST: 'Produktlistning',
  ADD_TO_CART: 'Lägg i varukorg',
  ADD_TO_CART_SHORT: 'Köp',
  ADD_FAVORITE: 'Lägg till som favorit',
  ADD: 'Lägg till',
  REMOVE_FAVORITE: 'Ta bort från favoriter',
  LOGO_ALT_TEXT: 'Logga',
  EX_VAT: 'exkl. moms',
  INC_VAT: 'inkl. moms',
  NEXT: 'Nästa',
  PREVIOUS: 'Föregående',
  READ_MORE: 'Läs mer',
  READ_LESS: 'Läs mindre',
  SHOW: 'Visa',
  HIDE: 'Dölj',
  LOAD_MORE: 'Ladda fler',
  LOAD_PREVIOUS: 'Ladda föregående',
  PAGINATION_SHOWING: 'Du har sett {sum} av {total}',
  PAGINATION_SHOWING_PARTIAL: 'Du visar {sum} av {total}',
  PAGINATION_BACK: 'Tillbaka till första sidan',
  FILTER_SORT: 'Filtrera & sortera',
  FILTERS: 'Filter',
  FILTER_LABEL_CATEGORIES: 'Kategorier',
  FILTER_LABEL_BRANDS: 'Författare',
  FILTER_LABEL_PRICE: 'Pris',
  FILTER_LABEL_CAMPAIGNS: 'Kampanjer',
  FILTER_LABEL_SKUS: 'Storlekar',
  FILTER_LABEL_DISCOUNT: 'Rea',
  FILTERS_ACTIVE: 'aktivt filter | aktiva filter',
  SORT_TITLE: 'Sortera på',
  SORT_LABEL_LATEST: 'Senast inkomna',
  SORT_LABEL_MOST_SOLD: 'Bästsäljare',
  SORT_LABEL_PRICE: 'Lägsta pris',
  SORT_LABEL_PRICE_DESC: 'Högsta pris',
  SORT_LABEL_RELEVANCE: 'Relevans',
  CONTINUE_SHOPPING: 'Fortsätt shoppa',
  CHECKOUT: 'Kassan',
  CART: 'Varukorg',
  COMPLETE_ORDER: 'Slutför köp',
  SHOP_AS: 'Handla som',
  CART_TOTAL: 'Totalt',
  CART_EMPTY: 'Din varukorg är tom',
  CART_TO_CHECKOUT: 'Gå till kassan',
  CART_ITEM_ADDED: 'Tillagd i varukorgen | Tillagda i varukorgen',
  QUANTITY: 'Antal',
  ORDER_CONFIRM_TITLE: 'Orderbekräftelse',
  ORDER_CONFIRM_THANKS: 'Tack för din order!',
  ORDER_SUMMARY_TITLE: 'Du har beställt',
  ORDER_SUMMARY_NO_CART: 'Det finns ingen varukorg att visa',
  CLOSE: 'Stäng',
  RESET_FILTER: 'Återställ',
  APPLY_FILTER: 'Använd & stäng',
  KLARNA_FAIL:
    'Något gick fel när din order skulle skapas i Klarna, gå tillbaka för att försöka igen.',
  KLARNA_FAIL_ERROR:
    'Kunde inte skapa order. Möjliga orsaker till detta är att någon produkt är slut i lager eller att det inte finns tillräckligt med saldo på ditt konto.',
  KLARNA_FAIL_OUTOFSTOCK:
    'En produkt i din är varukorg är tyvärr slut i lager. Var vänlig se över din varukorg och försök igen.',
  KLARNA_FAIL_BLOCKED:
    'Kunde inte skapa order då din användare är blockerad hos Klarna',
  GO_BACK: 'Gå tillbaka',
  PICK_COLOR: 'Välj färg',
  PICK_SKU: 'Välj storlek',
  PICK_VARIANT: 'Välj variant',
  STOCK_STATUS_IN_STOCK: 'I lager',
  STOCK_STATUS_OUT_OF_STOCK: 'Slut i lager',
  STOCK_STATUS_FEW_LEFT: 'Bara {quantity} kvar',
  STOCK_STATUS_OVERSELLABLE: 'Beställningsvara',
  STOCK_STATUS_STATIC: 'I lager',
  STOCK_STATUS_DELIVERY_TIME_IN_STOCK: 'Skickas inom 2-3 vardagar',
  STOCK_STATUS_DELIVERY_TIME_OUT_OF_STOCK: 'Lite längre leveranstid',
  STOCK_STATUS_DELIVERY_TIME_FEW_LEFT: 'Skickas inom 2-3 vardagar',
  STOCK_STATUS_DELIVERY_TIME_OVERSELLABLE: 'Lite längre leveranstid',
  STOCK_STATUS_DELIVERY_TIME_STATIC: 'Skickas inom 2-3 vardagar',
  OVERSELLABLE_CART_MESSAGE:
    'Din varukorg innehåller en eller flera varor som är beställningsvaror, vilket kan påverka leveranstiden för denna order.',
  MUST_CHOOSE_SKU: 'Du måste välja storlek först',
  CART_ADD_TOO_MANY:
    'Du kan tyvärr inte lägga fler i varukorgen. Endast {stock} kvar på lager',
  QUANTITY_THRESHOLD_REACHED:
    'Du kan inte addera flera då du redan har {quantity} i varukorgen',
  PRODUCT_DESCRIPTION: 'Produktbeskrivning',
  NO_PRODUCT_DESCRIPTION:
    'Det finns tyvärr ingen produktbeskrivning att visa för denna produkt.',
  PRODUCT_SPECIFICATION: 'Specifikation',
  NO_PRODUCT_SPECIFICATION:
    'Det finns tyvärr inga specifikationer att visa för denna produkt.',
  SEARCH_RESULTS_TITLE: 'Sökresultat',
  SEARCH_RESULTS_SEE_ALL: 'Se alla sökresultat',
  YOUR_RECENT_SEARCHES: 'Dina senaste sökningar',
  TOP_SEARCHES: 'Populära sökningar',
  SEARCH_NO_SUGGESTIONS: 'Du har inte sökt på något än',
  SEARCH_NO_RESULTS: 'Tyvärr hittades inget som matchar din sökning',
  SEARCH_RESULTS_PAGE_TITLE: 'Sökresultat för: {search}',
  RESET_FILTERS: 'Återställ filter',
  NO_PRODUCTS_MATCH: 'Inga produkter matchar ditt urval',
  SKU_NOT_CHOSEN: 'Ej vald',
  SKU_LABEL: 'Storlek',
  INPUT_OPTIONAL: 'frivilligt',
  EMAIL: 'E-mail',
  EMAIL_ERROR_NOT_VALID: 'Du måste ange en giltig email',
  PASSWORD: 'Lösenord',
  PASSWORD_CONFIRM: 'Bekräfta lösenord',
  NEW_PASSWORD: 'Nytt lösenord',
  NEW_PASSWORD_CONFIRM: 'Bekräfta nytt lösenord',
  CURRENT_PASSWORD: 'Nuvarande lösenord',
  PASSWORD_ERROR_WEAK: 'Ditt lösenord måste innehålla minst 7 tecken',
  PASSWORD_ERROR_EMPTY: 'Du måste ange ett lösenord',
  PASSWORD_ERROR_NO_MATCH: 'Lösenorden matchar inte',
  REMEMBER_ME: 'Kom ihåg mig',
  FORGOT_PASSWORD: 'Glömt lösenord?',
  NEWSLETTER_SUBSCRIBE_CHOICE: 'Ja, jag vill anmäla mig till nyhetsbrev',
  ACCOUNT_DISCLAIMER:
    'Genom att klicka "Skapa konto" accepterar du våra {0} samt vår {1}.',
  TERMS_AND_CONDITIONS: 'allmänna villkor',
  PRIVACY_POLICY: 'integritetspolicy',
  LOG_IN: 'Logga in',
  LOG_OUT: 'Logga ut',
  CREATE_ACCOUNT: 'Skapa konto',
  DELETE_ACCOUNT: 'Radera konto',
  RESET_PASSWORD: 'Återställ lösenord',
  CHANGE_PASSWORD: 'Byt lösenord',
  BACK_TO_LOGIN: 'Tillbaka till inloggning',
  ACCOUNT_FEEDBACK_CREATED: 'Ditt konto har blivit skapat, du är nu inloggad',
  ACCOUNT_FEEDBACK_CREDENTIALS: 'Fel email eller lösenord, försök gärna igen',
  ACCOUNT_FEEDBACK_LOGGED_IN: 'Du har loggats in',
  ACCOUNT_FEEDBACK_PASSWORD_RESET:
    'Ett mail för återställning av lösenord har skickats till dig',
  ACCOUNT_FEEDBACK_PASSWORD_CHANGED: 'Ditt lösenord har ändrats',
  ACCOUNT_FEEDBACK_FIELDS_NOT_VALID: 'Se till att alla fält är giltiga',
  ACCOUNT_FEEDBACK_ALREADY_EXISTS: 'Ett konto med denna email existerar redan',
  ACCOUNT_FEEDBACK_RESET_KEY_NOT_VALID:
    'Giltighetstiden för denna återställning har gått ut. För att kunna återställa ditt lösenord måste du begära lösenordsåterställning på nytt.',
  ACCOUNT_VIEW_DETAILS: 'Se detaljer',
  ACCOUNT_LOGGED_IN_AS: 'Inloggad som',
  SHIPPING_METHOD: 'Leverans',
  PAYMENT_METHOD: 'Betalning',
  TRACK_ORDER: 'Spåra paket',
  BILLING_ADDRESS: 'Fakturaadress',
  SHIPPING_ADDRESS: 'Leveransadress',
  ACCOUNT_ORDERS_IN_PROGRESS: 'Pågående',
  ACCOUNT_ORDER_HISTORY: 'Orderhistorik',
  CUSTOMER_SERVICE_BOX_TITLE: 'Behöver du hjälp?',
  CUSTOMER_SERVICE_BOX_TEXT:
    'Vi finns här för att hjälpa dig. Hör av dig till oss om du har problem.',
  ACCOUNT_ORDERS_TITLE: 'Ordrar',
  ACCOUNT_SETTINGS_TITLE: 'Inställningar',
  ACCOUNT_BALANCE_TITLE: 'Saldo',
  ACCOUNT_BALANCE_PAGE_TITLE: 'Aktuellt saldo',
  ACCOUNT_TITLE: 'Mina sidor',
  ACCOUNT_SETTINGS_CANCEL: 'Avbryt',
  ACCOUNT_SETTINGS_EDIT: 'Ändra',
  ACCOUNT_SETTINGS_SAVE: 'Spara ändringar',
  ACCOUNT_ACCOUNT_SETTINGS_TITLE: 'Kontoinställningar',
  ACCOUNT_USER_INFO_TITLE: 'Personlig information',
  ACCOUNT_ORGANIZATION_INFO_TITLE: 'Företagsinformation',
  ACCOUNT_BILLING_SHIPPING_TITLE: 'Leverans',
  ACCOUNT_NEWSLETTER_TITLE: 'Nyhetsbrev',
  ACCOUNT_PRIVACY_TITLE: 'Säkerhetsinställningar',
  ACCOUNT_GENDER_UNSPECIFIED: 'Ospecifierat',
  ACCOUNT_GENDER_WOMAN: 'Kvinna',
  ACCOUNT_GENDER_MAN: 'Man',
  ACCOUNT_ORDERS_EMPTY: 'Du har inte lagt några ordrar än',
  ACCOUNT_ORDER_STATUS_RECEIVED: 'Mottagen',
  ACCOUNT_ORDER_STATUS_PROCESSING: 'Behandlas',
  ACCOUNT_ORDER_STATUS_COMPLETED: 'Slutförd',
  ACCOUNT_ORDER_STATUS_CANCELLED: 'Avbruten',
  ACCOUNT_DELETE_PROMPT_TITLE: 'Radera konto?',
  ACCOUNT_DELETE_PROMPT_TEXT:
    'Vill du verkligen radera ditt konto? Denna handling kan ej ångras. Om du fortsätter kommer ditt konto att raderas och du loggas ut.',
  ACCOUNT_DELETE_PROMPT_BUTTON: 'Radera konto',
  ACCOUNT_DELETE_FEEDBACK: 'Ditt konto är nu raderat',
  ACCOUNT_SAVE_FEEDBACK: 'Dina ändringar har sparats',
  ACCOUNT_CHANGE_PASSWORD_ERROR:
    'Du angav fel lösenord och har loggats ut av säkerhetsskäl',
  ACCOUNT_RESET_PASSWORD_PAGE_TEXT: 'Ange ett nytt lösenord för ditt konto',
  ACCOUNT_LOGGING_IN_AS_USER: 'Du loggas in som användare..',
  ACCOUNT_SETTING_NOT_SPECIFIED: 'Ej angivet',
  REFUNDED: 'Återbetald',
  REFUNDS: 'Återbetalningar',
  FEEDBACK_ERROR:
    'Något gick tyvärr fel. Försök gärna igen och kontakta kundtjänst om problemet kvarstår.',
  FEEDBACK_REQUIRED_FIELD: 'Detta fält är obligatoriskt',
  FEEDBACK_PERSONAL_ID_NOT_VALID: 'Du måste ange ett giltigt personnummer',
  LABEL_FIRST_NAME: 'Förnamn',
  LABEL_LAST_NAME: 'Efternamn',
  LABEL_PHONE_NUMBER: 'Telefonnummer',
  LABEL_GENDER: 'Kön',
  LABEL_CARE_OF: 'C/O',
  LABEL_ADDRESS: 'Gatuadress',
  LABEL_ADDRESS_2: 'Lägenhetsnummer, byggnad, våning, etc.',
  LABEL_ZIP: 'Postnummer',
  LABEL_CITY: 'Ort',
  LABEL_COUNTRY: 'Land',
  LABEL_NEWSLETTER: 'Nyhetsbrev',
  LABEL_PERSONAL_ID: 'Personnummer',
  LABEL_ORDER_MESSAGE: 'Meddelande',
  LABEL_ENTRY_CODE: 'Portkod',
  LABEL_CUSTOMER_TYPE: 'Kundtyp',
  LABEL_ORGANIZATION_ID: 'Organisationsnummer',
  LABEL_COMPANY: 'Företagsnamn',
  ACCOUNT_PERSONAL_INFO_REMOVAL:
    'För att enbart ta bort personlig information, kontakta kundservice.',
  YES: 'Ja',
  NO: 'Nej',
  ERROR_PAGE_TITLE: 'Oj, något gick visst fel',
  ERROR_PAGE_404_TITLE: 'Sidan du söker kunde tyvärr inte hittas',
  ERROR_PAGE_BUTTON: 'Till startsidan',
  COOKIE_CONSENT_TEXT:
    'Vi använder tredjeparts-cookies för att anpassa innehåll, annonser och för att analysera sidtrafik.',
  COOKIE_CONSENT_LEARN_MORE: 'Läs mer',
  COOKIE_CONSENT_ALLOW: 'Tillåt alla cookies',
  COOKIE_CONSENT_DECLINE: 'Tillåt endast nödvändiga',
  ANCIENT_BROWSER_MESSAGE:
    'Denna webbsida kommer ej fungera optimalt då du använder en utdaterad browser. Vi rekommenderar att du byter innan du går vidare för att din upplevelse ska bli bättre.',
  PROMO_CODE_TITLE: 'Presentkort eller rabattkod?',
  PROMO_CODE_LABEL: 'Skriv in koden här',
  PROMO_CODE_BUTTON: 'Aktivera',
  PROMO_CODE_ACTIVATED: 'Din kod är nu aktiverad',
  PROMO_CODE_ERROR_REQUIREMENTS: 'Du har inte uppfyllt kraven för denna kod',
  PROMO_CODE_ERROR_INVALID: 'Koden är ej giltig',
  PROMO_CODE_ACTIVE: 'Koden {0} är aktiverad. {1}',
  PROMO_CODE_REMOVE: 'Ta bort',
  NEWSLETTER_TITLE: 'Nyhetsbrev',
  NEWSLETTER_TEXT:
    'Registrera dig för att få vårt nyhetsbrev och hålla dig uppdaterad om senaste nytt.',
  NEWSLETTER_PLACEHOLDER: 'din@email.com',
  NEWSLETTER_LABEL: '',
  NEWSLETTER_BUTTON: 'Registrera',
  NEWSLETTER_FEEDBACK_SUCCESS: 'Tack! Du är nu registrerad',
  CHECKOUT_YOUR_INFORMATION: 'Dina uppgifter',
  CHECKOUT_SUBTITLE_COUNTRY_INFO: 'Adressen ska vara i',
  CHECKOUT_ADD_SHIPPING_ADDRESS: 'Skicka till annan adress',
  CHECKOUT_MESSAGE_FIELD_DESCRIPTION:
    'Här kan du lämna ett meddelande till butiken',
  CHECKOUT_CONSENTS_TITLE: 'Hantering av personuppgifter',
  CHECKOUT_CONSENTS_TEXT:
    'För oss är det viktigt att dina uppgifter inte används till annat än det du gett tillstånd till. Nedan kan du se vad vi kan använda uppgifterna till och vad du får för fördelar av det.',
  CHECKOUT_CONSENT_ORDER: 'Jag har läst och godkänner {0}',
  CHECKOUT_CONSENT_NEWSLETTER:
    'Ja tack, jag vill få nyhetsbrev med spännande erbjudanden',
  CHECKOUT_CONSENT_SMS: 'Ja tack, jag vill få SMS med spännande erbjudanden',
  CHECKOUT_TERMS: 'köpvillkoren',
  CHECKOUT_TOTAL: 'Totalbelopp inkl. moms',
  CHECKOUT_SHOW_SUMMARY: 'Visa detaljer',
  CHECKOUT_HIDE_SUMMARY: 'Dölj detaljer',
  CHECKOUT_CONFIRM_TITLE: 'Tack för din order!',
  CHECKOUT_CONFIRM_TEXT:
    'Inom kort kommer du få en orderbekräftelse skickad till {email} med all information gällande din order.',
  CHECKOUT_CONFIRM_TEXT_NO_EMAIL:
    'Inom kort kommer du få en orderbekräftelse med all information gällande din order.',
  CHECKOUT_FEEDBACK_FIELDS_NOT_VALID:
    'Se till att alla fält är korrekt ifyllda',
  CHECKOUT_FEEDBACK_TERMS_NOT_ACCEPTED:
    'Du måste godkänna köpvillkoren innan du slutför ditt köp',
  CHECKOUT_PERSONAL_ID_DESCRIPTION:
    'Ditt personnummer behövs för att kunna skapa din faktura',
  CHECKOUT_ORGANIZATION_ID_DESCRIPTION:
    'Ditt organisationsnummer behövs för att kunna skapa din faktura',
  CHECKOUT_INVOICE_INFORMATION:
    'Vi skickar en faktura digitalt till den email du angivit ovan.',
  CHECKOUT_CHOOSE_SHIPPING: 'Leverans',
  CHECKOUT_CHOOSE_COUNTRY: 'Välj ditt land',
  CHECKOUT_SHOW_SHIPPING_OPTIONS: 'Visa fraktsätt',
  CHECKOUT_PAYMENT_GUARD:
    'Fyll i ditt postnummer ovan och välj fraktsätt, samt se till att alla fält är ifyllda innan du kan slutföra köpet.',
  CHECKOUT_LOCATION_GUARD:
    'Välj det land du handlar från ovan för att se tillgängliga fraktsätt',
  CHECKOUT_NO_SHIPPING_OPTIONS:
    'Hittade inga fraktalternativ för angivet postnummer',
  CHECKOUT_ENTER_ZIP:
    'Ange ditt postnummer för att visa tillgängliga fraktsätt',
  CHECKOUT_ZIP_PLACEHOLDER: 'Ditt postnummer',
  CHECKOUT_PAY: 'Betala',
  CHECKOUT_CHOOSE_PAYMENT: 'Välj betalsätt',
  CHECKOUT_ACTIVE_CAMPAIGNS: 'Aktiva kampanjer',
  ORDER_NUMBER: 'Ordernummer',
  CART_SUMMARY_SUBTOTAL: 'Delsumma',
  CART_SUMMARY_DISCOUNT: 'Rabatt',
  CART_SUMMARY_SHIPPING_FEE: 'Fraktavgift',
  CART_SUMMARY_ESTIMATED_SHIPPING_FEE: 'Estimerad fraktavgift',
  CART_SUMMARY_REGULAR_PRICE: 'Ordinarie pris',
  CART_SUMMARY_LEFT_TO_FREE_SHIPPING: 'kvar till fri frakt',
  CART_SUMMARY_VAT: 'Moms',
  CART_SUMMARY_TOTAL_EX_BALANCE: 'Totalt innan saldo',
  CART_SUMMARY_BALANCE: 'Saldo',
  FREE_SHIPPING: 'Fri frakt',
  SWEDEN: 'Sverige',
  BREADCRUMBS_HOME: 'Hem',
  BREADCRUMBS_SALE: 'Rea',
  NOTIFY_PANEL_TITLE: 'Bevaka produkt',
  NOTIFY_PANEL_DESCRIPTION:
    'Fyll i din e-postadress i fältet nedan för att få en avisering när produkten är tillbaka i lager igen.',
  NOTIFY_PANEL_BUTTON: 'Bevaka produkt',
  NOTIFY_PANEL_SUCCESS:
    'Din bevakning är registrerad. Du kommer att få ett mail när din produkt åter finns i lager',
  NOTIFY_ME: 'Bevaka',
  ALL_PAGE_TITLE: 'Nyheter',
  NAVIGATION_ALL_IN: 'Visa allt inom',
  NAVIGATION_GO_TO: 'Gå till',
  RELATED_PRODUCTS: 'Relaterad produkter',
  CHOOSE: 'Välj',
  CHOOSE_YOUR_COUNTRY: 'Välj land',
  MARKET_PANEL_TITLE: 'Välj region',
  MARKET_GROUP_SCANDINAVIA: 'Skandinavien',
  MARKET_GROUP_EU: 'Europa',
  REVIEW_WRITE: 'Skriv en recension',
  REVIEW_IN_ACCEPTANCE: 'Tack! Din recension kommer att publiceras inom kort',
  REVIEW_NEED_LOG_IN: 'Du måste logga in för att kunna skriva en recension',
  REVIEW_NAME_PLACEHOLDER: 'Ditt namn',
  REVIEW_COMMENT_PLACEHOLDER: 'Din recension',
  SEND: 'Skicka',
  FEEDBACK_ALL_INPUTS_REVIEW:
    'Vänligen fyll i all nödvändig information för att skicka en recension',
  PRODUCT_REVIEWS: 'Produktrecensioner',
  REVIEW_WRITTEN_BY: 'Skriven av:',
  REVIEWS_LIST_EMPTY: 'Denna produkt har inga recensioner ännu',
  REVIEW_FEEDBACK_RATE_REQUIRED:
    'Använd stjärnorna för att betygsätta produkten',
  BOOKHERO_DESCRIPTION_INTRO: 'På Bookhero kan du alltid hitta dina favoritböcker till riktigt låga priser. Vi har ett brett sortiment av litteratur inom olika kategorier, inklusive ',
  BOOKHERO_DESCRIPTION_OUTRO: 'Du betalar säkert med Swish, kort eller faktura och får böckerna hemlevererade snabbt och pålitligt med Budbee Box, Early Bird, Instabox eller DB Schenker.',
  FICTION: 'facklitteratur',
  FOOD_DRINK: 'mat & dryck',
  CHILDREN_YOUTH_BOOKS: 'barn- & ungdomsböcker',
  HOBBY_LEISURE: 'hobby- och fritidsböcker',
  BIOGRAPHIES: 'biografier',
  LITERATURE: 'skönlitteratur',
  AND: 'och',
};
