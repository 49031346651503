
/*
  Renders the start page (redirects to front page).
*/
export default {
  name: 'StartPage',
  methods: {},
  meta: {
    pageType: 'Start Page',
  },
};
