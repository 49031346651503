
export default {
  name: 'CaErrorLayout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return 'CaError404';
      }
      // catch everything else
      return 'CaError';
    },
  },
  mounted() {
    this.$store.dispatch('loading/end');
  },
};
