
/*
  Renders the front page.
*/
export default {
  name: 'FrontPage',
  data: () => ({}),
  methods: {},
  meta: {
    pageType: 'Front Page',
  },
};
