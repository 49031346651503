import locale951f0f1e from '../../languages/sv-SE.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"sv"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en-US.js","name":"English","domain":""},{"code":"sv","iso":"sv-SE","file":"sv-SE.js","name":"Swedish","domain":"https://bookhero.se"},{"code":"nb","iso":"nb-NO","file":"nb-NO.js","name":"Norsk","domain":""},{"code":"da","iso":"da-DK","file":"da-DK.js","name":"Dansk","domain":""},{"code":"fi","iso":"fi-FI","file":"fi-FI.js","name":"Finska","domain":""}],
  defaultLocale: "sv",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "languages/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://bookhero.se",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {"checkout/index":{"sv":"/kassan","en":"/checkout","da":"/kassen","fi":"/kassa","nb":"/kassen"},"account/orders":{"sv":"/mina-sidor/ordrar","en":"/my-account/orders","da":"/min-konto/bestillinger","fi":"/tilini/tilaukset","nb":"/min-konto/bestillinger"},"account/settings":{"sv":"/mina-sidor/installningar","en":"/my-account/settings","da":"/min-konto/indstillinger","fi":"/tilini/asetukset","nb":"/min-konto/innstillinger"},"account/balance":{"sv":"/mina-sidor/saldo","en":"/my-account/balance","da":"/min-konto/saldo","fi":"/tilini/saldo","nb":"/min-konto/saldo"},"favorites/index":{"sv":"/favoriter","en":"/favorites","da":"/favoritter","fi":"/suosikkeja","nb":"/favoritter"},"brands/index":{"sv":"/forfattare","en":"/brands","da":"/varemaerker","fi":"/tavaramerkkeja","nb":"/varemerker"},"list/all":{"sv":"/nyheter","en":"/news","da":"/nyheder","fi":"/uutuudet","nb":"/nyheter"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US.js","name":"English","domain":""},{"code":"sv","iso":"sv-SE","file":"sv-SE.js","name":"Swedish","domain":"https://bookhero.se"},{"code":"nb","iso":"nb-NO","file":"nb-NO.js","name":"Norsk","domain":""},{"code":"da","iso":"da-DK","file":"da-DK.js","name":"Dansk","domain":""},{"code":"fi","iso":"fi-FI","file":"fi-FI.js","name":"Finska","domain":""}],
  localeCodes: ["en","sv","nb","da","fi"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "u",
  21: "a",
  22: "g",
  23: "e",
  24: "s",
  25: "/",
  26: "e",
  27: "n",
  28: "-",
  29: "U",
  30: "S",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ",",
  36: "\"",
  37: "s",
  38: "v",
  39: "-",
  40: "S",
  41: "E",
  42: ".",
  43: "j",
  44: "s",
  45: "\"",
  46: ":",
  47: "\"",
  48: ".",
  49: ".",
  50: "/",
  51: "l",
  52: "a",
  53: "n",
  54: "g",
  55: "u",
  56: "a",
  57: "g",
  58: "e",
  59: "s",
  60: "/",
  61: "s",
  62: "v",
  63: "-",
  64: "S",
  65: "E",
  66: ".",
  67: "j",
  68: "s",
  69: "\"",
  70: ",",
  71: "\"",
  72: "n",
  73: "b",
  74: "-",
  75: "N",
  76: "O",
  77: ".",
  78: "j",
  79: "s",
  80: "\"",
  81: ":",
  82: "\"",
  83: ".",
  84: ".",
  85: "/",
  86: "l",
  87: "a",
  88: "n",
  89: "g",
  90: "u",
  91: "a",
  92: "g",
  93: "e",
  94: "s",
  95: "/",
  96: "n",
  97: "b",
  98: "-",
  99: "N",
  100: "O",
  101: ".",
  102: "j",
  103: "s",
  104: "\"",
  105: ",",
  106: "\"",
  107: "d",
  108: "a",
  109: "-",
  110: "D",
  111: "K",
  112: ".",
  113: "j",
  114: "s",
  115: "\"",
  116: ":",
  117: "\"",
  118: ".",
  119: ".",
  120: "/",
  121: "l",
  122: "a",
  123: "n",
  124: "g",
  125: "u",
  126: "a",
  127: "g",
  128: "e",
  129: "s",
  130: "/",
  131: "d",
  132: "a",
  133: "-",
  134: "D",
  135: "K",
  136: ".",
  137: "j",
  138: "s",
  139: "\"",
  140: ",",
  141: "\"",
  142: "f",
  143: "i",
  144: "-",
  145: "F",
  146: "I",
  147: ".",
  148: "j",
  149: "s",
  150: "\"",
  151: ":",
  152: "\"",
  153: ".",
  154: ".",
  155: "/",
  156: "l",
  157: "a",
  158: "n",
  159: "g",
  160: "u",
  161: "a",
  162: "g",
  163: "e",
  164: "s",
  165: "/",
  166: "f",
  167: "i",
  168: "-",
  169: "F",
  170: "I",
  171: ".",
  172: "j",
  173: "s",
  174: "\"",
  175: "}",
}

export const localeMessages = {
  'sv-SE.js': () => Promise.resolve(locale951f0f1e),
  'en-US.js': () => import('../../languages/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'nb-NO.js': () => import('../../languages/nb-NO.js' /* webpackChunkName: "lang-nb-NO.js" */),
  'da-DK.js': () => import('../../languages/da-DK.js' /* webpackChunkName: "lang-da-DK.js" */),
  'fi-FI.js': () => import('../../languages/fi-FI.js' /* webpackChunkName: "lang-fi-FI.js" */),
}
