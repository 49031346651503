import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22139184 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f3d619c0 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _126ea239 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _168b6cb3 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _43877ecc = () => interopDefault(import('../pages/list/all.vue' /* webpackChunkName: "pages/list/all" */))
const _6b8ef46e = () => interopDefault(import('../pages/content/_preview.vue' /* webpackChunkName: "" */))
const _b71c3b2a = () => interopDefault(import('../pages/start/index.vue' /* webpackChunkName: "pages/start/index" */))
const _16b8f736 = () => interopDefault(import('../pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _b669d53e = () => interopDefault(import('../pages/checkout/confirm.vue' /* webpackChunkName: "pages/checkout/confirm" */))
const _6b5186f2 = () => interopDefault(import('../pages/checkout/klarna-fail.vue' /* webpackChunkName: "pages/checkout/klarna-fail" */))
const _07382da2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _a658681e = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _3bc6c100 = () => interopDefault(import('../pages/account/balance.vue' /* webpackChunkName: "pages/account/balance" */))
const _3a991c5b = () => interopDefault(import('../pages/product/_alias.vue' /* webpackChunkName: "" */))
const _39f30a96 = () => interopDefault(import('../pages/list/_category.vue' /* webpackChunkName: "" */))
const _064c4ec2 = () => interopDefault(import('../pages/list/_brand.vue' /* webpackChunkName: "" */))
const _2b1c7a91 = () => interopDefault(import('../pages/content/_alias.vue' /* webpackChunkName: "pages/content/_alias" */))
const _138834ee = () => interopDefault(import('../pages/list/_discountCampaign.vue' /* webpackChunkName: "" */))
const _2315a394 = () => interopDefault(import('../pages/list/_list.vue' /* webpackChunkName: "pages/list/_list" */))
const _24bcce40 = () => interopDefault(import('../pages/list/_search.vue' /* webpackChunkName: "pages/list/_search" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _22139184,
    name: "404___sv"
  }, {
    path: "/account",
    component: _cbd4fa14,
    name: "account___sv"
  }, {
    path: "/da",
    component: _2dfb1658,
    name: "index___da"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/favoriter",
    component: _f3d619c0,
    name: "favorites___sv"
  }, {
    path: "/fi",
    component: _2dfb1658,
    name: "index___fi"
  }, {
    path: "/forfattare",
    component: _126ea239,
    name: "brands___sv"
  }, {
    path: "/kassan",
    component: _168b6cb3,
    name: "checkout___sv"
  }, {
    path: "/nb",
    component: _2dfb1658,
    name: "index___nb"
  }, {
    path: "/nyheter",
    component: _43877ecc,
    name: "list-all___sv"
  }, {
    path: "/preview-widgets",
    component: _6b8ef46e,
    name: "preview-widgets___sv"
  }, {
    path: "/start",
    component: _b71c3b2a,
    name: "start___sv"
  }, {
    path: "/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___sv"
  }, {
    path: "/checkout/confirm",
    component: _b669d53e,
    name: "checkout-confirm___sv"
  }, {
    path: "/checkout/klarna-fail",
    component: _6b5186f2,
    name: "checkout-klarna-fail___sv"
  }, {
    path: "/da/404",
    component: _22139184,
    name: "404___da"
  }, {
    path: "/da/account",
    component: _cbd4fa14,
    name: "account___da"
  }, {
    path: "/da/favoritter",
    component: _f3d619c0,
    name: "favorites___da"
  }, {
    path: "/da/kassen",
    component: _168b6cb3,
    name: "checkout___da"
  }, {
    path: "/da/nyheder",
    component: _43877ecc,
    name: "list-all___da"
  }, {
    path: "/da/preview-widgets",
    component: _6b8ef46e,
    name: "preview-widgets___da"
  }, {
    path: "/da/start",
    component: _b71c3b2a,
    name: "start___da"
  }, {
    path: "/da/varemaerker",
    component: _126ea239,
    name: "brands___da"
  }, {
    path: "/en/404",
    component: _22139184,
    name: "404___en"
  }, {
    path: "/en/account",
    component: _cbd4fa14,
    name: "account___en"
  }, {
    path: "/en/brands",
    component: _126ea239,
    name: "brands___en"
  }, {
    path: "/en/checkout",
    component: _168b6cb3,
    name: "checkout___en"
  }, {
    path: "/en/favorites",
    component: _f3d619c0,
    name: "favorites___en"
  }, {
    path: "/en/news",
    component: _43877ecc,
    name: "list-all___en"
  }, {
    path: "/en/preview-widgets",
    component: _6b8ef46e,
    name: "preview-widgets___en"
  }, {
    path: "/en/start",
    component: _b71c3b2a,
    name: "start___en"
  }, {
    path: "/fi/404",
    component: _22139184,
    name: "404___fi"
  }, {
    path: "/fi/account",
    component: _cbd4fa14,
    name: "account___fi"
  }, {
    path: "/fi/kassa",
    component: _168b6cb3,
    name: "checkout___fi"
  }, {
    path: "/fi/preview-widgets",
    component: _6b8ef46e,
    name: "preview-widgets___fi"
  }, {
    path: "/fi/start",
    component: _b71c3b2a,
    name: "start___fi"
  }, {
    path: "/fi/suosikkeja",
    component: _f3d619c0,
    name: "favorites___fi"
  }, {
    path: "/fi/tavaramerkkeja",
    component: _126ea239,
    name: "brands___fi"
  }, {
    path: "/fi/uutuudet",
    component: _43877ecc,
    name: "list-all___fi"
  }, {
    path: "/mina-sidor/installningar",
    component: _07382da2,
    name: "account-settings___sv"
  }, {
    path: "/mina-sidor/ordrar",
    component: _a658681e,
    name: "account-orders___sv"
  }, {
    path: "/mina-sidor/saldo",
    component: _3bc6c100,
    name: "account-balance___sv"
  }, {
    path: "/nb/404",
    component: _22139184,
    name: "404___nb"
  }, {
    path: "/nb/account",
    component: _cbd4fa14,
    name: "account___nb"
  }, {
    path: "/nb/favoritter",
    component: _f3d619c0,
    name: "favorites___nb"
  }, {
    path: "/nb/kassen",
    component: _168b6cb3,
    name: "checkout___nb"
  }, {
    path: "/nb/nyheter",
    component: _43877ecc,
    name: "list-all___nb"
  }, {
    path: "/nb/preview-widgets",
    component: _6b8ef46e,
    name: "preview-widgets___nb"
  }, {
    path: "/nb/start",
    component: _b71c3b2a,
    name: "start___nb"
  }, {
    path: "/nb/varemerker",
    component: _126ea239,
    name: "brands___nb"
  }, {
    path: "/da/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___da"
  }, {
    path: "/da/checkout/confirm",
    component: _b669d53e,
    name: "checkout-confirm___da"
  }, {
    path: "/da/checkout/klarna-fail",
    component: _6b5186f2,
    name: "checkout-klarna-fail___da"
  }, {
    path: "/da/min-konto/bestillinger",
    component: _a658681e,
    name: "account-orders___da"
  }, {
    path: "/da/min-konto/indstillinger",
    component: _07382da2,
    name: "account-settings___da"
  }, {
    path: "/da/min-konto/saldo",
    component: _3bc6c100,
    name: "account-balance___da"
  }, {
    path: "/en/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___en"
  }, {
    path: "/en/checkout/confirm",
    component: _b669d53e,
    name: "checkout-confirm___en"
  }, {
    path: "/en/checkout/klarna-fail",
    component: _6b5186f2,
    name: "checkout-klarna-fail___en"
  }, {
    path: "/en/my-account/balance",
    component: _3bc6c100,
    name: "account-balance___en"
  }, {
    path: "/en/my-account/orders",
    component: _a658681e,
    name: "account-orders___en"
  }, {
    path: "/en/my-account/settings",
    component: _07382da2,
    name: "account-settings___en"
  }, {
    path: "/fi/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___fi"
  }, {
    path: "/fi/checkout/confirm",
    component: _b669d53e,
    name: "checkout-confirm___fi"
  }, {
    path: "/fi/checkout/klarna-fail",
    component: _6b5186f2,
    name: "checkout-klarna-fail___fi"
  }, {
    path: "/fi/tilini/asetukset",
    component: _07382da2,
    name: "account-settings___fi"
  }, {
    path: "/fi/tilini/saldo",
    component: _3bc6c100,
    name: "account-balance___fi"
  }, {
    path: "/fi/tilini/tilaukset",
    component: _a658681e,
    name: "account-orders___fi"
  }, {
    path: "/nb/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___nb"
  }, {
    path: "/nb/checkout/confirm",
    component: _b669d53e,
    name: "checkout-confirm___nb"
  }, {
    path: "/nb/checkout/klarna-fail",
    component: _6b5186f2,
    name: "checkout-klarna-fail___nb"
  }, {
    path: "/nb/min-konto/bestillinger",
    component: _a658681e,
    name: "account-orders___nb"
  }, {
    path: "/nb/min-konto/innstillinger",
    component: _07382da2,
    name: "account-settings___nb"
  }, {
    path: "/nb/min-konto/saldo",
    component: _3bc6c100,
    name: "account-balance___nb"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___sv"
  }, {
    path: "/da/p/(.*)/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-sub-level___da"
  }, {
    path: "/en/p/(.*)/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-sub-level___en"
  }, {
    path: "/fi/p/(.*)/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-sub-level___fi"
  }, {
    path: "/nb/p/(.*)/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-sub-level___nb"
  }, {
    path: "/da/c/(.*)/:category",
    component: _39f30a96,
    name: "plp-sub-category___da"
  }, {
    path: "/da/p/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-level___da"
  }, {
    path: "/en/c/(.*)/:category",
    component: _39f30a96,
    name: "plp-sub-category___en"
  }, {
    path: "/en/p/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-level___en"
  }, {
    path: "/fi/c/(.*)/:category",
    component: _39f30a96,
    name: "plp-sub-category___fi"
  }, {
    path: "/fi/p/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-level___fi"
  }, {
    path: "/nb/c/(.*)/:category",
    component: _39f30a96,
    name: "plp-sub-category___nb"
  }, {
    path: "/nb/p/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-level___nb"
  }, {
    path: "/da/b/:brand+",
    component: _064c4ec2,
    name: "plp-brand___da"
  }, {
    path: "/da/c/:category",
    component: _39f30a96,
    name: "plp-category___da"
  }, {
    path: "/da/content/:alias?",
    component: _2b1c7a91,
    name: "content-alias___da"
  }, {
    path: "/da/content/:preview?",
    component: _6b8ef46e,
    name: "content-preview___da"
  }, {
    path: "/da/dc/:discountCampaign+",
    component: _138834ee,
    name: "plp-discount-campaign___da"
  }, {
    path: "/da/list/:brand?",
    component: _064c4ec2,
    name: "list-brand___da"
  }, {
    path: "/da/list/:category?",
    component: _39f30a96,
    name: "list-category___da"
  }, {
    path: "/da/list/:discountCampaign?",
    component: _138834ee,
    name: "list-discountCampaign___da"
  }, {
    path: "/da/list/:list?",
    component: _2315a394,
    name: "list-list___da"
  }, {
    path: "/da/list/:search?",
    component: _24bcce40,
    name: "list-search___da"
  }, {
    path: "/da/p/:alias",
    component: _3a991c5b,
    name: "pdp___da"
  }, {
    path: "/da/product/:alias?",
    component: _3a991c5b,
    name: "product-alias___da"
  }, {
    path: "/da/s/:search",
    component: _24bcce40,
    name: "plp-search___da"
  }, {
    path: "/en/b/:brand+",
    component: _064c4ec2,
    name: "plp-brand___en"
  }, {
    path: "/en/c/:category",
    component: _39f30a96,
    name: "plp-category___en"
  }, {
    path: "/en/content/:alias?",
    component: _2b1c7a91,
    name: "content-alias___en"
  }, {
    path: "/en/content/:preview?",
    component: _6b8ef46e,
    name: "content-preview___en"
  }, {
    path: "/en/dc/:discountCampaign+",
    component: _138834ee,
    name: "plp-discount-campaign___en"
  }, {
    path: "/en/list/:brand?",
    component: _064c4ec2,
    name: "list-brand___en"
  }, {
    path: "/en/list/:category?",
    component: _39f30a96,
    name: "list-category___en"
  }, {
    path: "/en/list/:discountCampaign?",
    component: _138834ee,
    name: "list-discountCampaign___en"
  }, {
    path: "/en/list/:list?",
    component: _2315a394,
    name: "list-list___en"
  }, {
    path: "/en/list/:search?",
    component: _24bcce40,
    name: "list-search___en"
  }, {
    path: "/en/p/:alias",
    component: _3a991c5b,
    name: "pdp___en"
  }, {
    path: "/en/product/:alias?",
    component: _3a991c5b,
    name: "product-alias___en"
  }, {
    path: "/en/s/:search",
    component: _24bcce40,
    name: "plp-search___en"
  }, {
    path: "/fi/b/:brand+",
    component: _064c4ec2,
    name: "plp-brand___fi"
  }, {
    path: "/fi/c/:category",
    component: _39f30a96,
    name: "plp-category___fi"
  }, {
    path: "/fi/content/:alias?",
    component: _2b1c7a91,
    name: "content-alias___fi"
  }, {
    path: "/fi/content/:preview?",
    component: _6b8ef46e,
    name: "content-preview___fi"
  }, {
    path: "/fi/dc/:discountCampaign+",
    component: _138834ee,
    name: "plp-discount-campaign___fi"
  }, {
    path: "/fi/list/:brand?",
    component: _064c4ec2,
    name: "list-brand___fi"
  }, {
    path: "/fi/list/:category?",
    component: _39f30a96,
    name: "list-category___fi"
  }, {
    path: "/fi/list/:discountCampaign?",
    component: _138834ee,
    name: "list-discountCampaign___fi"
  }, {
    path: "/fi/list/:list?",
    component: _2315a394,
    name: "list-list___fi"
  }, {
    path: "/fi/list/:search?",
    component: _24bcce40,
    name: "list-search___fi"
  }, {
    path: "/fi/p/:alias",
    component: _3a991c5b,
    name: "pdp___fi"
  }, {
    path: "/fi/product/:alias?",
    component: _3a991c5b,
    name: "product-alias___fi"
  }, {
    path: "/fi/s/:search",
    component: _24bcce40,
    name: "plp-search___fi"
  }, {
    path: "/nb/b/:brand+",
    component: _064c4ec2,
    name: "plp-brand___nb"
  }, {
    path: "/nb/c/:category",
    component: _39f30a96,
    name: "plp-category___nb"
  }, {
    path: "/nb/content/:alias?",
    component: _2b1c7a91,
    name: "content-alias___nb"
  }, {
    path: "/nb/content/:preview?",
    component: _6b8ef46e,
    name: "content-preview___nb"
  }, {
    path: "/nb/dc/:discountCampaign+",
    component: _138834ee,
    name: "plp-discount-campaign___nb"
  }, {
    path: "/nb/list/:brand?",
    component: _064c4ec2,
    name: "list-brand___nb"
  }, {
    path: "/nb/list/:category?",
    component: _39f30a96,
    name: "list-category___nb"
  }, {
    path: "/nb/list/:discountCampaign?",
    component: _138834ee,
    name: "list-discountCampaign___nb"
  }, {
    path: "/nb/list/:list?",
    component: _2315a394,
    name: "list-list___nb"
  }, {
    path: "/nb/list/:search?",
    component: _24bcce40,
    name: "list-search___nb"
  }, {
    path: "/nb/p/:alias",
    component: _3a991c5b,
    name: "pdp___nb"
  }, {
    path: "/nb/product/:alias?",
    component: _3a991c5b,
    name: "product-alias___nb"
  }, {
    path: "/nb/s/:search",
    component: _24bcce40,
    name: "plp-search___nb"
  }, {
    path: "/fi/l/*",
    component: _2315a394,
    name: "plp___fi"
  }, {
    path: "/da/l/*",
    component: _2315a394,
    name: "plp___da"
  }, {
    path: "/nb/l/*",
    component: _2315a394,
    name: "plp___nb"
  }, {
    path: "/en/l/*",
    component: _2315a394,
    name: "plp___en"
  }, {
    path: "/p/(.*)/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-sub-level___sv"
  }, {
    path: "/c/(.*)/:category",
    component: _39f30a96,
    name: "plp-sub-category___sv"
  }, {
    path: "/p/(.*)/:alias",
    component: _3a991c5b,
    name: "pdp-level___sv"
  }, {
    path: "/b/:brand+",
    component: _064c4ec2,
    name: "plp-brand___sv"
  }, {
    path: "/c/:category",
    component: _39f30a96,
    name: "plp-category___sv"
  }, {
    path: "/content/:alias?",
    component: _2b1c7a91,
    name: "content-alias___sv"
  }, {
    path: "/content/:preview?",
    component: _6b8ef46e,
    name: "content-preview___sv"
  }, {
    path: "/da/:alias",
    component: _2b1c7a91,
    name: "content___da"
  }, {
    path: "/dc/:discountCampaign+",
    component: _138834ee,
    name: "plp-discount-campaign___sv"
  }, {
    path: "/en/:alias",
    component: _2b1c7a91,
    name: "content___en"
  }, {
    path: "/fi/:alias",
    component: _2b1c7a91,
    name: "content___fi"
  }, {
    path: "/list/:brand?",
    component: _064c4ec2,
    name: "list-brand___sv"
  }, {
    path: "/list/:category?",
    component: _39f30a96,
    name: "list-category___sv"
  }, {
    path: "/list/:discountCampaign?",
    component: _138834ee,
    name: "list-discountCampaign___sv"
  }, {
    path: "/list/:list?",
    component: _2315a394,
    name: "list-list___sv"
  }, {
    path: "/list/:search?",
    component: _24bcce40,
    name: "list-search___sv"
  }, {
    path: "/nb/:alias",
    component: _2b1c7a91,
    name: "content___nb"
  }, {
    path: "/p/:alias",
    component: _3a991c5b,
    name: "pdp___sv"
  }, {
    path: "/product/:alias?",
    component: _3a991c5b,
    name: "product-alias___sv"
  }, {
    path: "/s/:search",
    component: _24bcce40,
    name: "plp-search___sv"
  }, {
    path: "/l/*",
    component: _2315a394,
    name: "plp___sv"
  }, {
    path: "/:alias",
    component: _2b1c7a91,
    name: "content___sv"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
