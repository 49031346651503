export const CaActiveFilters = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaActiveFilters/CaActiveFilters.vue' /* webpackChunkName: "components/ca-active-filters" */).then(c => wrapFunctional(c.default || c))
export const CaAddress = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaAddress/CaAddress.vue' /* webpackChunkName: "components/ca-address" */).then(c => wrapFunctional(c.default || c))
export const CaBrandAndName = () => import('../../components/atoms/CaBrandAndName/CaBrandAndName.vue' /* webpackChunkName: "components/ca-brand-and-name" */).then(c => wrapFunctional(c.default || c))
export const CaBreadcrumbs = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaBreadcrumbs/CaBreadcrumbs.vue' /* webpackChunkName: "components/ca-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CaButton = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaButton/CaButton.vue' /* webpackChunkName: "components/ca-button" */).then(c => wrapFunctional(c.default || c))
export const CaCampaigns = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaCampaigns/CaCampaigns.vue' /* webpackChunkName: "components/ca-campaigns" */).then(c => wrapFunctional(c.default || c))
export const CaCartSummary = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaCartSummary/CaCartSummary.vue' /* webpackChunkName: "components/ca-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CaCheckoutExternal = () => import('../../components/atoms/CaCheckoutExternal/CaCheckoutExternal.vue' /* webpackChunkName: "components/ca-checkout-external" */).then(c => wrapFunctional(c.default || c))
export const CaCheckoutSection = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaCheckoutSection/CaCheckoutSection.vue' /* webpackChunkName: "components/ca-checkout-section" */).then(c => wrapFunctional(c.default || c))
export const CaClickable = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaClickable/CaClickable.vue' /* webpackChunkName: "components/ca-clickable" */).then(c => wrapFunctional(c.default || c))
export const CaColorIcon = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaColorIcon/CaColorIcon.vue' /* webpackChunkName: "components/ca-color-icon" */).then(c => wrapFunctional(c.default || c))
export const CaConditionalRootElement = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaConditionalRootElement/CaConditionalRootElement.vue' /* webpackChunkName: "components/ca-conditional-root-element" */).then(c => wrapFunctional(c.default || c))
export const CaContainer = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaContainer/CaContainer.vue' /* webpackChunkName: "components/ca-container" */).then(c => wrapFunctional(c.default || c))
export const CaCustomerServiceBox = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaCustomerServiceBox/CaCustomerServiceBox.vue' /* webpackChunkName: "components/ca-customer-service-box" */).then(c => wrapFunctional(c.default || c))
export const CaCustomerTypeToggle = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaCustomerTypeToggle/CaCustomerTypeToggle.vue' /* webpackChunkName: "components/ca-customer-type-toggle" */).then(c => wrapFunctional(c.default || c))
export const CaFeedback = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaFeedback/CaFeedback.vue' /* webpackChunkName: "components/ca-feedback" */).then(c => wrapFunctional(c.default || c))
export const CaFilterMultiTreeNode = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaFilterMultiTreeNode/CaFilterMultiTreeNode.vue' /* webpackChunkName: "components/ca-filter-multi-tree-node" */).then(c => wrapFunctional(c.default || c))
export const CaFilterTrigger = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaFilterTrigger/CaFilterTrigger.vue' /* webpackChunkName: "components/ca-filter-trigger" */).then(c => wrapFunctional(c.default || c))
export const CaFlag = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaFlag/CaFlag.vue' /* webpackChunkName: "components/ca-flag" */).then(c => wrapFunctional(c.default || c))
export const CaFooterNavigation = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaFooterNavigation/CaFooterNavigation.vue' /* webpackChunkName: "components/ca-footer-navigation" */).then(c => wrapFunctional(c.default || c))
export const CaGlobalMessage = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaGlobalMessage/CaGlobalMessage.vue' /* webpackChunkName: "components/ca-global-message" */).then(c => wrapFunctional(c.default || c))
export const CaHtml = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaHtml/CaHtml.vue' /* webpackChunkName: "components/ca-html" */).then(c => wrapFunctional(c.default || c))
export const CaIcon = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaIcon/CaIcon.vue' /* webpackChunkName: "components/ca-icon" */).then(c => wrapFunctional(c.default || c))
export const CaIconAndText = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaIconAndText/CaIconAndText.vue' /* webpackChunkName: "components/ca-icon-and-text" */).then(c => wrapFunctional(c.default || c))
export const CaIconButton = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaIconButton/CaIconButton.vue' /* webpackChunkName: "components/ca-icon-button" */).then(c => wrapFunctional(c.default || c))
export const CaImage = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaImage/CaImage.vue' /* webpackChunkName: "components/ca-image" */).then(c => wrapFunctional(c.default || c))
export const CaInputCheckbox = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaInputCheckbox/CaInputCheckbox.vue' /* webpackChunkName: "components/ca-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CaInputRadio = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaInputRadio/CaInputRadio.vue' /* webpackChunkName: "components/ca-input-radio" */).then(c => wrapFunctional(c.default || c))
export const CaInputSelect = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaInputSelect/CaInputSelect.vue' /* webpackChunkName: "components/ca-input-select" */).then(c => wrapFunctional(c.default || c))
export const CaInputText = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaInputText/CaInputText.vue' /* webpackChunkName: "components/ca-input-text" */).then(c => wrapFunctional(c.default || c))
export const CaInputTextarea = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaInputTextarea/CaInputTextarea.vue' /* webpackChunkName: "components/ca-input-textarea" */).then(c => wrapFunctional(c.default || c))
export const CaListCountBar = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaListCountBar/CaListCountBar.vue' /* webpackChunkName: "components/ca-list-count-bar" */).then(c => wrapFunctional(c.default || c))
export const CaLogo = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaLogo/CaLogo.vue' /* webpackChunkName: "components/ca-logo" */).then(c => wrapFunctional(c.default || c))
export const CaMarketItem = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaMarketItem/CaMarketItem.vue' /* webpackChunkName: "components/ca-market-item" */).then(c => wrapFunctional(c.default || c))
export const CaMarketSelectorButton = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaMarketSelectorButton/CaMarketSelectorButton.vue' /* webpackChunkName: "components/ca-market-selector-button" */).then(c => wrapFunctional(c.default || c))
export const CaNotificationBadge = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaNotificationBadge/CaNotificationBadge.vue' /* webpackChunkName: "components/ca-notification-badge" */).then(c => wrapFunctional(c.default || c))
export const CaOverlay = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaOverlay/CaOverlay.vue' /* webpackChunkName: "components/ca-overlay" */).then(c => wrapFunctional(c.default || c))
export const CaPrice = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaPrice/CaPrice.vue' /* webpackChunkName: "components/ca-price" */).then(c => wrapFunctional(c.default || c))
export const CaProductMeta = () => import('../../components/atoms/CaProductMeta/CaProductMeta.vue' /* webpackChunkName: "components/ca-product-meta" */).then(c => wrapFunctional(c.default || c))
export const CaProductPriceHistory = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaProductPriceHistory/CaProductPriceHistory.vue' /* webpackChunkName: "components/ca-product-price-history" */).then(c => wrapFunctional(c.default || c))
export const CaQuickAddProduct = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaQuickAddProduct/CaQuickAddProduct.vue' /* webpackChunkName: "components/ca-quick-add-product" */).then(c => wrapFunctional(c.default || c))
export const CaRefund = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaRefund/CaRefund.vue' /* webpackChunkName: "components/ca-refund" */).then(c => wrapFunctional(c.default || c))
export const CaSecondaryNavItem = () => import('../../components/atoms/CaSecondaryNavItem/CaSecondaryNavItem.vue' /* webpackChunkName: "components/ca-secondary-nav-item" */).then(c => wrapFunctional(c.default || c))
export const CaSkeleton = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSkeleton/CaSkeleton.vue' /* webpackChunkName: "components/ca-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CaSlide = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSlide/CaSlide.vue' /* webpackChunkName: "components/ca-slide" */).then(c => wrapFunctional(c.default || c))
export const CaSliderArrows = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSliderArrows/CaSliderArrows.vue' /* webpackChunkName: "components/ca-slider-arrows" */).then(c => wrapFunctional(c.default || c))
export const CaSliderDots = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSliderDots/CaSliderDots.vue' /* webpackChunkName: "components/ca-slider-dots" */).then(c => wrapFunctional(c.default || c))
export const CaSnackbar = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSnackbar/CaSnackbar.vue' /* webpackChunkName: "components/ca-snackbar" */).then(c => wrapFunctional(c.default || c))
export const CaSpecifications = () => import('../../components/atoms/CaSpecifications/CaSpecifications.vue' /* webpackChunkName: "components/ca-specifications" */).then(c => wrapFunctional(c.default || c))
export const CaSpinner = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSpinner/CaSpinner.vue' /* webpackChunkName: "components/ca-spinner" */).then(c => wrapFunctional(c.default || c))
export const CaStarRating = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaStarRating/CaStarRating.vue' /* webpackChunkName: "components/ca-star-rating" */).then(c => wrapFunctional(c.default || c))
export const CaStatusLabel = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaStatusLabel/CaStatusLabel.vue' /* webpackChunkName: "components/ca-status-label" */).then(c => wrapFunctional(c.default || c))
export const CaStockDisplay = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaStockDisplay/CaStockDisplay.vue' /* webpackChunkName: "components/ca-stock-display" */).then(c => wrapFunctional(c.default || c))
export const CaSvgAsset = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaSvgAsset/CaSvgAsset.vue' /* webpackChunkName: "components/ca-svg-asset" */).then(c => wrapFunctional(c.default || c))
export const CaTabPanel = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaTabPanel/CaTabPanel.vue' /* webpackChunkName: "components/ca-tab-panel" */).then(c => wrapFunctional(c.default || c))
export const CaToggleFavorite = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaToggleFavorite/CaToggleFavorite.vue' /* webpackChunkName: "components/ca-toggle-favorite" */).then(c => wrapFunctional(c.default || c))
export const CaTopBarNavigation = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaTopBarNavigation/CaTopBarNavigation.vue' /* webpackChunkName: "components/ca-top-bar-navigation" */).then(c => wrapFunctional(c.default || c))
export const CaVideo = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaVideo/CaVideo.vue' /* webpackChunkName: "components/ca-video" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetBannerButton = () => import('../../node_modules/@geins/ralph-ui/components/atoms/CaWidgetBannerButton/CaWidgetBannerButton.vue' /* webpackChunkName: "components/ca-widget-banner-button" */).then(c => wrapFunctional(c.default || c))
export const CaAccordionItem = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaAccordionItem/CaAccordionItem.vue' /* webpackChunkName: "components/ca-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const CaAccordionNavigation = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaAccordionNavigation/CaAccordionNavigation.vue' /* webpackChunkName: "components/ca-accordion-navigation" */).then(c => wrapFunctional(c.default || c))
export const CaAccountHeader = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaAccountHeader/CaAccountHeader.vue' /* webpackChunkName: "components/ca-account-header" */).then(c => wrapFunctional(c.default || c))
export const CaAccountPanel = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaAccountPanel/CaAccountPanel.vue' /* webpackChunkName: "components/ca-account-panel" */).then(c => wrapFunctional(c.default || c))
export const CaAccountSettingsBlock = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaAccountSettingsBlock/CaAccountSettingsBlock.vue' /* webpackChunkName: "components/ca-account-settings-block" */).then(c => wrapFunctional(c.default || c))
export const CaAddedToCart = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaAddedToCart/CaAddedToCart.vue' /* webpackChunkName: "components/ca-added-to-cart" */).then(c => wrapFunctional(c.default || c))
export const CaCartProduct = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCartProduct/CaCartProduct.vue' /* webpackChunkName: "components/ca-cart-product" */).then(c => wrapFunctional(c.default || c))
export const CaCategoryDisplay = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCategoryDisplay/CaCategoryDisplay.vue' /* webpackChunkName: "components/ca-category-display" */).then(c => wrapFunctional(c.default || c))
export const CaCheckoutInvoice = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCheckoutInvoice/CaCheckoutInvoice.vue' /* webpackChunkName: "components/ca-checkout-invoice" */).then(c => wrapFunctional(c.default || c))
export const CaContentPanel = () => import('../../components/molecules/CaContentPanel/CaContentPanel.vue' /* webpackChunkName: "components/ca-content-panel" */).then(c => wrapFunctional(c.default || c))
export const CaCookieConsent = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCookieConsent/CaCookieConsent.vue' /* webpackChunkName: "components/ca-cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const CaCountrySelector = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCountrySelector/CaCountrySelector.vue' /* webpackChunkName: "components/ca-country-selector" */).then(c => wrapFunctional(c.default || c))
export const CaCountrySelectorPanel = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCountrySelectorPanel/CaCountrySelectorPanel.vue' /* webpackChunkName: "components/ca-country-selector-panel" */).then(c => wrapFunctional(c.default || c))
export const CaCurrencySelector = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaCurrencySelector/CaCurrencySelector.vue' /* webpackChunkName: "components/ca-currency-selector" */).then(c => wrapFunctional(c.default || c))
export const CaDisplayCart = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaDisplayCart/CaDisplayCart.vue' /* webpackChunkName: "components/ca-display-cart" */).then(c => wrapFunctional(c.default || c))
export const CaError = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaError/CaError.vue' /* webpackChunkName: "components/ca-error" */).then(c => wrapFunctional(c.default || c))
export const CaError404 = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaError404/CaError404.vue' /* webpackChunkName: "components/ca-error404" */).then(c => wrapFunctional(c.default || c))
export const CaFavorites = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaFavorites/CaFavorites.vue' /* webpackChunkName: "components/ca-favorites" */).then(c => wrapFunctional(c.default || c))
export const CaFilter = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaFilter/CaFilter.vue' /* webpackChunkName: "components/ca-filter" */).then(c => wrapFunctional(c.default || c))
export const CaFilterMulti = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaFilterMulti/CaFilterMulti.vue' /* webpackChunkName: "components/ca-filter-multi" */).then(c => wrapFunctional(c.default || c))
export const CaFilterMultiTreeView = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaFilterMultiTreeView/CaFilterMultiTreeView.vue' /* webpackChunkName: "components/ca-filter-multi-tree-view" */).then(c => wrapFunctional(c.default || c))
export const CaFilterRange = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaFilterRange/CaFilterRange.vue' /* webpackChunkName: "components/ca-filter-range" */).then(c => wrapFunctional(c.default || c))
export const CaFooterNavigationAccordion = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaFooterNavigationAccordion/CaFooterNavigationAccordion.vue' /* webpackChunkName: "components/ca-footer-navigation-accordion" */).then(c => wrapFunctional(c.default || c))
export const CaHeaderNavigation = () => import('../../components/molecules/CaHeaderNavigation/CaHeaderNavigation.vue' /* webpackChunkName: "components/ca-header-navigation" */).then(c => wrapFunctional(c.default || c))
export const CaInfoPageMenu = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaInfoPageMenu/CaInfoPageMenu.vue' /* webpackChunkName: "components/ca-info-page-menu" */).then(c => wrapFunctional(c.default || c))
export const CaListFilters = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaListFilters/CaListFilters.vue' /* webpackChunkName: "components/ca-list-filters" */).then(c => wrapFunctional(c.default || c))
export const CaListPagination = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaListPagination/CaListPagination.vue' /* webpackChunkName: "components/ca-list-pagination" */).then(c => wrapFunctional(c.default || c))
export const CaListSettings = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaListSettings/CaListSettings.vue' /* webpackChunkName: "components/ca-list-settings" */).then(c => wrapFunctional(c.default || c))
export const CaListTop = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaListTop/CaListTop.vue' /* webpackChunkName: "components/ca-list-top" */).then(c => wrapFunctional(c.default || c))
export const CaMarketGroup = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaMarketGroup/CaMarketGroup.vue' /* webpackChunkName: "components/ca-market-group" */).then(c => wrapFunctional(c.default || c))
export const CaMiniCart = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaMiniCart/CaMiniCart.vue' /* webpackChunkName: "components/ca-mini-cart" */).then(c => wrapFunctional(c.default || c))
export const CaModal = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaModal/CaModal.vue' /* webpackChunkName: "components/ca-modal" */).then(c => wrapFunctional(c.default || c))
export const CaNavigationSlim = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaNavigationSlim/CaNavigationSlim.vue' /* webpackChunkName: "components/ca-navigation-slim" */).then(c => wrapFunctional(c.default || c))
export const CaNewsletter = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaNewsletter/CaNewsletter.vue' /* webpackChunkName: "components/ca-newsletter" */).then(c => wrapFunctional(c.default || c))
export const CaNotifyPanel = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaNotifyPanel/CaNotifyPanel.vue' /* webpackChunkName: "components/ca-notify-panel" */).then(c => wrapFunctional(c.default || c))
export const CaNshift = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaNshift/CaNshift.vue' /* webpackChunkName: "components/ca-nshift" */).then(c => wrapFunctional(c.default || c))
export const CaOrderSummary = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaOrderSummary/CaOrderSummary.vue' /* webpackChunkName: "components/ca-order-summary" */).then(c => wrapFunctional(c.default || c))
export const CaPanelNavigation = () => import('../../components/molecules/CaPanelNavigation/CaPanelNavigation.vue' /* webpackChunkName: "components/ca-panel-navigation" */).then(c => wrapFunctional(c.default || c))
export const CaPaymentOptions = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaPaymentOptions/CaPaymentOptions.vue' /* webpackChunkName: "components/ca-payment-options" */).then(c => wrapFunctional(c.default || c))
export const CaProductAccordion = () => import('../../components/molecules/CaProductAccordion/CaProductAccordion.vue' /* webpackChunkName: "components/ca-product-accordion" */).then(c => wrapFunctional(c.default || c))
export const CaProductGallery = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaProductGallery/CaProductGallery.vue' /* webpackChunkName: "components/ca-product-gallery" */).then(c => wrapFunctional(c.default || c))
export const CaProductGalleryModal = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaProductGalleryModal/CaProductGalleryModal.vue' /* webpackChunkName: "components/ca-product-gallery-modal" */).then(c => wrapFunctional(c.default || c))
export const CaProductList = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaProductList/CaProductList.vue' /* webpackChunkName: "components/ca-product-list" */).then(c => wrapFunctional(c.default || c))
export const CaProductListSlider = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaProductListSlider/CaProductListSlider.vue' /* webpackChunkName: "components/ca-product-list-slider" */).then(c => wrapFunctional(c.default || c))
export const CaProductPriceHistoryPanel = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaProductPriceHistoryPanel/CaProductPriceHistoryPanel.vue' /* webpackChunkName: "components/ca-product-price-history-panel" */).then(c => wrapFunctional(c.default || c))
export const CaProductQuantity = () => import('../../components/molecules/CaProductQuantity/CaProductQuantity.vue' /* webpackChunkName: "components/ca-product-quantity" */).then(c => wrapFunctional(c.default || c))
export const CaProductTabs = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaProductTabs/CaProductTabs.vue' /* webpackChunkName: "components/ca-product-tabs" */).then(c => wrapFunctional(c.default || c))
export const CaPromoCode = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaPromoCode/CaPromoCode.vue' /* webpackChunkName: "components/ca-promo-code" */).then(c => wrapFunctional(c.default || c))
export const CaPrompt = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaPrompt/CaPrompt.vue' /* webpackChunkName: "components/ca-prompt" */).then(c => wrapFunctional(c.default || c))
export const CaQuickAddProducts = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaQuickAddProducts/CaQuickAddProducts.vue' /* webpackChunkName: "components/ca-quick-add-products" */).then(c => wrapFunctional(c.default || c))
export const CaReadMore = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaReadMore/CaReadMore.vue' /* webpackChunkName: "components/ca-read-more" */).then(c => wrapFunctional(c.default || c))
export const CaReviewForm = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaReviewForm/CaReviewForm.vue' /* webpackChunkName: "components/ca-review-form" */).then(c => wrapFunctional(c.default || c))
export const CaReviewsList = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaReviewsList/CaReviewsList.vue' /* webpackChunkName: "components/ca-reviews-list" */).then(c => wrapFunctional(c.default || c))
export const CaSearch = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaSearch/CaSearch.vue' /* webpackChunkName: "components/ca-search" */).then(c => wrapFunctional(c.default || c))
export const CaSecondaryNav = () => import('../../components/molecules/CaSecondaryNav/CaSecondaryNav.vue' /* webpackChunkName: "components/ca-secondary-nav" */).then(c => wrapFunctional(c.default || c))
export const CaShippingOptions = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaShippingOptions/CaShippingOptions.vue' /* webpackChunkName: "components/ca-shipping-options" */).then(c => wrapFunctional(c.default || c))
export const CaSlider = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaSlider/CaSlider.vue' /* webpackChunkName: "components/ca-slider" */).then(c => wrapFunctional(c.default || c))
export const CaTabs = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaTabs/CaTabs.vue' /* webpackChunkName: "components/ca-tabs" */).then(c => wrapFunctional(c.default || c))
export const CaVariantPicker = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaVariantPicker/CaVariantPicker.vue' /* webpackChunkName: "components/ca-variant-picker" */).then(c => wrapFunctional(c.default || c))
export const CaVariantPickerColor = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaVariantPickerColor/CaVariantPickerColor.vue' /* webpackChunkName: "components/ca-variant-picker-color" */).then(c => wrapFunctional(c.default || c))
export const CaVariantPickerDisplay = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaVariantPickerDisplay/CaVariantPickerDisplay.vue' /* webpackChunkName: "components/ca-variant-picker-display" */).then(c => wrapFunctional(c.default || c))
export const CaVariantPickerImage = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaVariantPickerImage/CaVariantPickerImage.vue' /* webpackChunkName: "components/ca-variant-picker-image" */).then(c => wrapFunctional(c.default || c))
export const CaVariantPickerPanel = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaVariantPickerPanel/CaVariantPickerPanel.vue' /* webpackChunkName: "components/ca-variant-picker-panel" */).then(c => wrapFunctional(c.default || c))
export const CaWidget = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidget/CaWidget.vue' /* webpackChunkName: "components/ca-widget" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetArea = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetArea/CaWidgetArea.vue' /* webpackChunkName: "components/ca-widget-area" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetBanner = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetBanner/CaWidgetBanner.vue' /* webpackChunkName: "components/ca-widget-banner" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetButtons = () => import('../../components/molecules/CaWidgetButtons/CaWidgetButtons.vue' /* webpackChunkName: "components/ca-widget-buttons" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetContainer = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetContainer/CaWidgetContainer.vue' /* webpackChunkName: "components/ca-widget-container" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetFlowbox = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetFlowbox/CaWidgetFlowbox.vue' /* webpackChunkName: "components/ca-widget-flowbox" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetHtml = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetHtml/CaWidgetHtml.vue' /* webpackChunkName: "components/ca-widget-html" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetImage = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetImage/CaWidgetImage.vue' /* webpackChunkName: "components/ca-widget-image" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetJson = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetJson/CaWidgetJson.vue' /* webpackChunkName: "components/ca-widget-json" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetProductList = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetProductList/CaWidgetProductList.vue' /* webpackChunkName: "components/ca-widget-product-list" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetRichText = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetRichText/CaWidgetRichText.vue' /* webpackChunkName: "components/ca-widget-rich-text" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetText = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetText/CaWidgetText.vue' /* webpackChunkName: "components/ca-widget-text" */).then(c => wrapFunctional(c.default || c))
export const CaWidgetVideo = () => import('../../node_modules/@geins/ralph-ui/components/molecules/CaWidgetVideo/CaWidgetVideo.vue' /* webpackChunkName: "components/ca-widget-video" */).then(c => wrapFunctional(c.default || c))
export const CaAccountPage = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaAccountPage/CaAccountPage.vue' /* webpackChunkName: "components/ca-account-page" */).then(c => wrapFunctional(c.default || c))
export const CaAccountSettings = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaAccountSettings/CaAccountSettings.vue' /* webpackChunkName: "components/ca-account-settings" */).then(c => wrapFunctional(c.default || c))
export const CaCart = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaCart/CaCart.vue' /* webpackChunkName: "components/ca-cart" */).then(c => wrapFunctional(c.default || c))
export const CaCheckoutHeader = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaCheckoutHeader/CaCheckoutHeader.vue' /* webpackChunkName: "components/ca-checkout-header" */).then(c => wrapFunctional(c.default || c))
export const CaFilterPanel = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaFilterPanel/CaFilterPanel.vue' /* webpackChunkName: "components/ca-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const CaMarketPanel = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaMarketPanel/CaMarketPanel.vue' /* webpackChunkName: "components/ca-market-panel" */).then(c => wrapFunctional(c.default || c))
export const CaMenuPanel = () => import('../../node_modules/@geins/ralph-ui/components/organisms/CaMenuPanel/CaMenuPanel.vue' /* webpackChunkName: "components/ca-menu-panel" */).then(c => wrapFunctional(c.default || c))
export const CaBookheroDescription = () => import('../../components/molecules/CaBookheroDescription/CaBookheroDescription.vue' /* webpackChunkName: "components/ca-bookhero-description" */).then(c => wrapFunctional(c.default || c))
export const CaCheckoutConfirmation = () => import('../../components/molecules/CaCheckoutConfirmation/CaCheckoutConfirmation.vue' /* webpackChunkName: "components/ca-checkout-confirmation" */).then(c => wrapFunctional(c.default || c))
export const CaCheckout = () => import('../../components/organisms/CaCheckout/CaCheckout.vue' /* webpackChunkName: "components/ca-checkout" */).then(c => wrapFunctional(c.default || c))
export const CaFooter = () => import('../../components/organisms/CaFooter/CaFooter.vue' /* webpackChunkName: "components/ca-footer" */).then(c => wrapFunctional(c.default || c))
export const CaHeader = () => import('../../components/organisms/CaHeader/CaHeader.vue' /* webpackChunkName: "components/ca-header" */).then(c => wrapFunctional(c.default || c))
export const CaListPage = () => import('../../components/organisms/CaListPage/CaListPage.vue' /* webpackChunkName: "components/ca-list-page" */).then(c => wrapFunctional(c.default || c))
export const CaProductCard = () => import('../../components/organisms/CaProductCard/CaProductCard.vue' /* webpackChunkName: "components/ca-product-card" */).then(c => wrapFunctional(c.default || c))
export const CaTopBar = () => import('../../components/organisms/CaTopBar/CaTopBar.vue' /* webpackChunkName: "components/ca-top-bar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
